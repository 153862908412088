<template>
  <div class="pt-10">
    <div class="vx-col mx-10">
      <vs-card class="p-6">
        <div class="vx-breadcrumb md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-center">
              <router-link :to="{ name: 'director-learning-centre-list' }"
                >Manage</router-link
              >
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>
            <li class="inline-flex items-center">
              <router-link :to="{ name: 'director-learning-centre-list' }"
                >Early Learning Centre</router-link
              >
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>

            <li class="inline-flex items-center">
              <a href="javascript:void(0)" class>Add Learning Centre</a>
            </li>
          </ul>
        </div>
        <!-- <vs-divider class="mb-0 md:block hidden"></vs-divider> -->
        <div class="w-full flex flex-wrap ">
          <div class="w-full md:w-1/2 md:pr-8">
            <div class="vx-col w-full my-8 vs-con-loading__container">
              <h2>Centre Details</h2>
              <template v-if="logo">
                <!-- Image Container -->
                <div class="img-container-rect flex items-center">
                  <img :src="logo" alt="img" class="responsive" />
                </div>

                <!-- Image upload Buttons -->
                <div class="modify-img flex mt-5">
                  <input
                    type="file"
                    class="hidden"
                    ref="updateImgInput"
                    @change="updateCurrImg"
                    accept="image/*"
                  />
                  <!-- <a class="mr-4"  href="#" @click="$refs.updateImgInput.click()">Update Photo</a>
                <a href="#"  @click="logo = null">Remove Photo</a>-->
                  <vs-button @click="$refs.updateImgInput.click()"
                    >Update Photo</vs-button
                  >
                  <vs-button @click="logo = null" class="ml-8" color="danger"
                    >Remove Photo</vs-button
                  >
                </div>
              </template>

              <div class="upload-img mt-5" v-if="!logo">
                <input
                  type="file"
                  class="hidden"
                  ref="uploadImgInput"
                  @change="updateCurrImg"
                  accept="image/*"
                />
                <!-- <a href="#" @click="$refs.uploadImgInput.click()">Upload Photo</a> -->
                <vs-button @click="$refs.uploadImgInput.click()" type="filled"
                  >Upload Photo
                </vs-button>
              </div>
            </div>
            <!-- NAME -->
            <vs-input
              label-placeholder="Centre Name"
              v-model="companyName"
              class="w-full mt-5"
              name="companyName"
              v-validate="'required'"
              data-vv-as="Centre name"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('companyName')"
              >{{ errors.first("companyName") }}</span
            >
            <vs-input
              label-placeholder="Phone Number"
              v-model="contactNumber"
              class="mt-5 w-full"
              name="contactNumber"
              v-validate="'required|numeric'"
              data-vv-as="phone number"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('contactNumber')"
              >{{ errors.first("contactNumber") }}</span
            >

            <vs-input
              class="w-full mt-5"
              name="email"
              v-validate="'required|email'"
              label-placeholder="Email"
              v-model="email"
              data-vv-as="Email"
            />
            <span class="text-danger text-sm" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
            <div class="vx-col w-full mt-5">
              <div class="select-wrapper">
                <div class="vs-component is-label-placeholder">
                  <label class="vs-input--label">Country</label>
                  <v-select
                    :options="countryOptions"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="countryFilter"
                    class="mb-4 md:mb-0"
                    data-vv-as="Country"
                  >
                    <template #header>
                      <label for class="label">Country</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-if="attributes" v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="vx-col w-full mt-5">
              <div class="select-wrapper">
                <div class="vs-component is-label-placeholder">
                  <v-select
                    :options="stateOptions"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="stateFilter"
                    class="mb-4 md:mb-0"
                    data-vv-as="State"
                  >
                    <template #header>
                      <label for class="label">State</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-if="attributes" v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5 ml-0">
                <input
                  type="text"
                  label-placeholder="Street Address"
                  ref="autocomplete"
                  v-validate="'required'"
                  data-vv-as="Street Address"
                  name="streetAddress"
                  v-model="streetAddress"
                  class="vs-inputx vs-input--input normal"
                  :class="{ hasValue: streetAddress }"
                />
              </div>
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('streetAddress')"
              >{{ errors.first("streetAddress") }}</span
            >
            <vs-input
              label-placeholder="Town/City"
              v-model="town"
              class="mt-5 w-full"
              name="town"
              v-validate="'required'"
              data-vv-as="Town"
            />
            <span class="text-danger text-sm" v-show="errors.has('town')">{{
              errors.first("town")
            }}</span>
            <vs-input
              label-placeholder="Postcode"
              v-model="postCode"
              class="mt-5 w-full"
              name="postCode"
              v-validate="'required'"
              data-vv-as="Post Code"
            />
            <span class="text-danger text-sm" v-show="errors.has('postCode')">{{
              errors.first("postCode")
            }}</span>

            <div class="flex flex-wrap items-center pt-6 justify-end">
              <vs-button color="danger" @click="cancelBtn">Cancel</vs-button>
              <vs-button
                class="ml-8"
                @click="submitData"
                :disabled="!isFormValid"
                >Save</vs-button
              >
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div
              class="vx-col w-full my-8 vs-con-loading__container"
              v-if="!director.isRegionalDirector"
            >
              <h2>Add regional Directors</h2>

              <div class="vx-col w-full mt-5">
                <div class="select-wrapper">
                  <div class="vs-component is-label-placeholder">
                    <ul>
                      <li class="mb-4" v-for="index in numberofIteration" :key="index">
                        <v-select
                          :options="regionalDirectorOption"
                          :reduce="director => director._id"
                          :clearable="false"
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"
                          v-model="regionalDirectorValue[index - 1]"
                          class="mb-4 md:mb-0"
                          label="fullName"
                          placeholder="Select"
                        >
                          <template #header>
                            <label for class="label">Regional Directors</label>
                          </template>
                          <template v-slot:option="option">
                            {{ option.fullName }}
                          </template>
                        </v-select>

                        <!--<select id="one" name="select" v-model="regionalDirectorValue[index - 1]" @change="onChange($event)">
                      <option v-for="(item, ind) in regionalDirectorOption" :key="ind" :value="item._id">{{item.fullName}}</option>
                    </select>-->
                        <a
                          href="javascript::void()"
                          @click="removeIteration(index)"
                          v-if="numberofIteration > 1"
                          >Remove</a
                        >
                      </li>
                    </ul>

                    <a href="javascript::void()" @click="numberofIteration++"
                      >Add another</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-card>
    </div>
  </div>
</template>
<script
  type="text/javascript"
  async
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD2_dLcglU5iyLUQ9hYiUuvpAyy7iRT3-o&libraries=places"
></script>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import _ from "lodash";
import { loaded } from "vue2-google-maps";

export default {
  data() {
    return {
      dataId: null,
      directorId: "",
      companyName: "",
      contactNumber: "",
      fullName: "",
      email: "",
      logo: null,
      image: null,
      settings: {
        // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      streetAddress: "",
      town: "",
      postCode: "",
      countryOptions: [],
      stateOptions: [],
      ageOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      ageFilter: { label: "Select age group", value: "" },
      customError: "",
      address: {
        displayAddress: "",
        suburb: "",
        state: "",
        street: "",
        streetNumber: "",
        postCode: "",
        location: {
          type: "point",
          coordinates: []
        },
        addressData: []
      },
      checkDisplayAddress: "",

      regionalDirectorOption: [],
      regionalDirectorValue: [],
      numberofIteration: 1
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    },
    regionalDirectorValue(obj) {
      console.log(obj);
    }
  },

  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.companyName &&
        this.contactNumber &&
        /*  && this.fullName */
        this.email &&
        this.logo &&
        this.countryFilter.value != "all" &&
        this.stateFilter.value != "all" &&
        this.streetAddress &&
        this.town &&
        this.postCode /* &&
        this.ageFilter.value != "" */
      );
    },
    director() {
      return this.$store.state.AppActiveUser;
    }
  },
  async mounted() {
    //  console.log( this.google );
    this.$gmapApiPromiseLazy().then(async () => {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        { types: ["geocode"] }
      );
      // , componentRestrictions: {country: "au"}
      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();
        this.errors.remove("streetAddress");
        let ac = place.address_components;
        this.address.addressData = place.address_components;
        this.address.addressData[0].checkDisplayAddress =
          place.formatted_address;
        this.checkDisplayAddress = place.formatted_address;
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();
        this.address.displayAddress = place.formatted_address;
        this.streetAddress = place.formatted_address;
        this.address.location.coordinates[0] = lon;
        this.address.location.coordinates[1] = lat;
      });
    });
  },
  methods: {
    ...mapActions("center", [
      "updateDirectorCenter",
      "addDirectorCenter",
      "imageUpload",
      "getDirectorAllCountries",
      "getAgeGroup"
    ]),
    ...mapActions("director", ["getRegionalDirectors"]),
    onChange(event) {
      console.log(event.target.value, this.regionalDirectorValue);
    },
    removeIteration(index) {
      this.regionalDirectorValue.splice(index - 1, 1);
      this.numberofIteration--;
      console.log(this.regionalDirectorValue);
      //this.regionalDirectorValue[index-1]
    },
    place_changed(a) {},
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async submitData() {
      await this.$validator.validateAll().then(async result => {
        if (result) {
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("companyName", this.companyName);
          data.append("contactNumber", this.contactNumber);

          if (this.director.isRegionalDirector) {
            data.append("directorId", this.director.mainDirectorId);
            data.append("groupId", this.director.mainDirectorId);
          } else {
            data.append("directorId", this.director.id);
            data.append("groupId", this.director.id);
          }

          data.append("fullName", "");
          data.append("email", this.email);
          data.append("logo", this.image);
          data.append("state", this.stateFilter.value);
          data.append("country", this.countryFilter.value);
          data.append("displayAddress", this.streetAddress);
          let sA = this.streetAddress.split(",");
          data.append("streetAddress", sA[0]);
          data.append("town", this.town);
          data.append("postCode", this.postCode);
          data.append("location", JSON.stringify(this.address.location));
          data.append(
            "regionalDirectors",
            JSON.stringify(this.regionalDirectorValue)
          );

          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();
            await this.updateDirectorCenter(data)
              .then(res => {
                if (res.status === 200) {
                  this.showMessage(
                    "Success",
                    "Centre Updated successfully.",
                    "success"
                  );
                  data.country = this.countryFilter.value;
                  // this.$emit("refreshData", true);
                }
                this.$vs.loading.close();
                this.$router.push({ name: "director-learning-centre-list" });
              })
              .catch(err => {
                this.$vs.loading.close();
              });
          } else {
            if (this.logo == null) {
              this.customError = "Image field is required.";
              return;
            }
            this.$vs.loading();
            await this.addDirectorCenter(data)
              .then(res => {
                this.$vs.loading.close();
                if (res.data.status === 401) {
                  this.showMessage("Failed", res.data.message, "danger");
                  // this.$emit("refreshData", true);
                } else if (res.status === 200) {
                  this.dataId = res.data._id;
                  this.showMessage(
                    "Success",
                    "Centre added successfully.",
                    "success"
                  );
                  this.$router.push({ name: "director-learning-centre-list" });
                }
              })
              .catch(err => {
                this.$vs.loading.close();
                if (err.response.status == 422) {
                  this.showMessage(
                    "Failed",
                    err.response.data.message,
                    "danger"
                  );
                }else{
                  this.showMessage("Failed", "Failed to add centre", "danger");
                }
              });

            // this.$router.push({name: "director-learning-centre-list"});
          }
        }
      });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    async getRegionalDirectorsOfDirector() {
      if (this.director.isRegionalDirector) {
        this.regionalDirectorOption.push(this.director);
        this.regionalDirectorValue.push(this.director._id);
      } else {
        await this.getRegionalDirectors()
          .then(res => {
            this.regionalDirectorOption = res.data.data;
            this.$vs.loading.close();
          })
          .catch(err => {
            this.$vs.loading.close();
          });
      }
    },
    cancelBtn() {
      this.$router.push({ name: "director-learning-centre-list" });
    }
  },
  created() {
    this.getAgeGroup()
      .then(res => {
        this.ageOptions = res;
      })
      .catch(err => {});
    this.getDirectorAllCountries()
      .then(res => {
        // console.log( res );
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch(err => {
        // console.error(err)
      });

    this.getRegionalDirectorsOfDirector();
  },
  components: {
    vSelect
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
